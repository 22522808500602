/* RobotoMono-Bold.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-Bold.ttf') format('truetype');
  font-weight: 700;
}

/* RobotoMono-BoldItalic.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* RobotoMono-ExtraLight.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

/* RobotoMono-ExtraLightItalic.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-ExtraLightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* RobotoMono-Italic.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-Italic.ttf') format('truetype');
  font-style: italic;
}

/* RobotoMono-Light.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-Light.ttf') format('truetype');
  font-weight: 300;
}

/* RobotoMono-LightItalic.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* RobotoMono-Medium.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-Medium.ttf') format('truetype');
  font-weight: 500;
}

/* RobotoMono-MediumItalic.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

/* RobotoMono-Regular.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-Regular.ttf') format('truetype');
}

/* RobotoMono-SemiBold.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

/* RobotoMono-SemiBoldItalic.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

/* RobotoMono-Thin.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-Thin.ttf') format('truetype');
  font-weight: 100;
}

/* RobotoMono-ThinItalic.ttf */
@font-face {
  font-family: 'Roboto Mono';
  src: url('/public/fonts/RobotoMono/RobotoMono-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Inter-Black.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

/* Inter-Bold.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

/* Inter-ExtraBold.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

/* Inter-ExtraLight.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

/* Inter-Light.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

/* Inter-Medium.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

/* Inter-Regular.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

/* Inter-SemiBold.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

/* Inter-Thin.ttf */
@font-face {
  font-family: 'Inter';
  src: url('/public/fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

/* Questrial-Regular.ttf */
@font-face {
  font-family: 'Questrial';
  src: url('/public/fonts/Questrial/Questrial-Regular.ttf') format('truetype');
  font-weight: 400;
}
